<template>
	<div>
		<div class="information-box">
			<img class="user" :src="url" alt="">
			<div class="information">
				<p class="name"></p>
				<p class="authentication">免冠照审核：
					<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 0">已申请</span>
					<span style="color: #67C23A;" v-if="this.$store.state.realnameornot.requeststatus == 1">已通过</span>
					<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 2">认证未通过</span>
					<span style="color: #DF2D10;" v-if="this.$store.state.realnameornot.requeststatus == 3">未认证</span>
				</p>
				<p class="number"></p>
			</div>
		</div>
		<div class="but" @click="recertification">重新上传</div>
		<p class="title">个人免冠照片重新上传后，需1个工作日完成审核。审核期间，官网裁判员注册信息公示及运动员成绩查询等栏目的展示将显示原照片。</p>
		<p class="title">如有问题，请联系:(010)63827176。</p>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				url:''
			}
		},
		created() {
			this.$api.getFile({objectName:this.$store.state.realnameornot.headPic}).then(res=>{
				if(!this.$verification(this.$store.state.realnameornot.headPic)){
					this.$convert( res.data.data.result.url).then((fileUrl) => {
						this.url = fileUrl
						this.$forceUpdate()
					})
				}else{
					this.url = require('@/assets/image/default.png')
				}
			})
		},
		methods: {
			recertification(){
				if(this.$store.state.realnameornot.caStatus == 0){
					if(this.$store.state.realnameornot.requeststatus != 1){
						return this.$alert('请先完成身份验证并通过审核', '提示', {
							confirmButtonText: '关闭',
							callback: action => { }
						});
					}
				}else{
					if(this.$store.state.realnameornot.caStatus != 1){
						return this.$alert('请先完成身份验证并通过审核', '提示', {
							confirmButtonText: '关闭',
							callback: action => { }
						});
					}
				}
				this.$emit('uploadagain')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.information-box {
		display: flex;
	}

	.user {
		width: 102PX;
		height: 126PX;
		margin-top: 37PX;
		margin-left: 47PX;
		border-radius: 10PX;
	}

	.information {
		margin-left: 34PX;
		margin-top: 47PX;
	}

	.name {
		font-size: 18PX;
		font-weight: 800;
		color: #333333;
	}

	.authentication {
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-top: 45PX;
	}

	.number {
		font-size: 18PX;
		font-weight: 500;
		color: #333333;
		margin-top: 13PX;
	}
	.but{
		width: 320.2PX;
		height: 67PX;
		background-image: linear-gradient(to right , #6FB2FF, #095FFF);
		font-size: 24PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 47PX;
		margin-top: 52PX;
		cursor: pointer;
	}

	.title{
		margin-left: 47PX;
		margin-top: 20PX;
	}
</style>