<template>
	<div>
		<div class="tel">
			<p class="telnumber" style="margin-left: 0;">个人免冠照片：</p>
			<div class="upload-box">
				<div class="upload">
					<SingleImage @uploadSuccess="handleSuccess1" :fileList="fileList1" @uploaddelete="uploaddelete">
					</SingleImage>
					<div class="example">
						<p>正确示范</p>
						<img src="../../assets/image/brand.jpg" alt="" style="width: 102PX;">
					</div>
					<div class="briefintroduction">
						<div>请上传本人近期正面、免冠电子证件照（背景需为纯色）。</div>
						<div>请上传符合要求的照片，否则会影响后续实名认证审核。</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tel">
			<div style="color: red;">*</div>
			<p class="telnumber">照片公示授权：</p>
			<el-radio label="1" v-model="radio2" style="margin-left: 17PX;"></el-radio>
			<p style="font-size: 15PX;">我同意北京市体育竞赛管理和国际交流中心使用我所提交的个人免冠照片作为官网裁判员注册信息公示及运动员成绩查询等栏目的展示，以便使用裁判员或运动员相关服务。</p>
		</div>

		<div class="button">
			<div class="save" @click="save">提交审核</div>
		</div>

		<P class="tel">个人免冠照照片提交后，我们将在1个工作日完成审核，请您耐心等待，如有问题，请联系（010）63827176</P>
	</div>
</template>

<script>
import SingleImage from './component/SingleImage.vue'
export default {
	name: '',
	components: {
		SingleImage
	},
	props: {
		edit: {
			type: Boolean,
			default: false
		},
		distinguish: {
			type: [String, Number],
			default: 0
		},
		idtype: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			radio2: '',
			info: {
				userid: '',
				headPic: '',
			},
			fileList1:[]
		}
	},
	created() {
		if(!this.$verification(this.$store.state.realnameornot.headPic)){
			this.info = {
				headPic: this.$store.state.realnameornot.headPic,
				userid: this.$store.state.userid
			}
			if (this.$store.state.realnameornot.headPic != null) {
				this.fileList1 = [{
					name: this.$store.state.realnameornot.headPic,
					url: this.$store.state.realnameornot.headPic
				}]
			}
		}
		this.userInfo()
	},
	methods: {
		userInfo() {
			let date = {
				phone: this.$store.state.logininformation.phone,
				username: this.$store.state.logininformation.username,
				realname: this.$store.state.logininformation.realname
			}
			this.$api.userInfo(date).then(res => {
				res.data.data.phone = this.$decrypt(res.data.data.phone)
				res.data.data.realname = this.$decrypt(res.data.data.realname)
				res.data.data.username = this.$decrypt(res.data.data.username)
				this.info.phone = res.data.data.phone
			})
		},
		handleSuccess1(e) { // 个人免冠照片
			this.info.headPic = e.message
		},
		uploaddelete(e) {
			if (e == this.info.headPic) {
				this.info.headPic = ''
			}
		},
		save() {
			this.info.userid = this.$store.state.userid

			if (this.$verification(this.info.headPic)) {
				return this.$message.error('请上传个人免冠照片')
			} else if (this.radio2 == 0) {
				return this.$message.error('请勾选照片公示授权')
			}

			this.$api.reSubmitFaceAuth(this.info).then(res => {
				this.$message.success('提交成功')
				this.$store.commit("EXIT_REALN")
				setTimeout(() => {
					this.$emit('photoautoexamine')
					this.info = {
						userid: '',
						headPic: '',
					}
				}, 500)
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.tel {
	display: flex;
	padding-top: 27PX;
	margin-left: 47PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 18PX;
	font-weight: 400;
	color: #333333;
	margin-left: 10PX;
	flex-shrink: 0
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
	margin-left: 37PX;
}

.tel ::v-deep .el-input__inner {
	width: 312PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.upload-box {
	display: flex;
	flex-direction: column;
	margin-left: 35PX;
}

.upload {
	display: flex;
}

.upload ::v-deep .el-upload-dragger {
	width: 195PX;
	height: 127PX;
	border: 1PX dashed #2368E1;
}

.example {
	display: flex;
}

.example p {
	font-size: 18PX !important;
	font-weight: 500;
	color: rgba(10, 109, 243, 0.58) !important;
	margin-left: 77PX;
	margin-top: 0 !important;
	flex-shrink: 0;
}

.example img {
	width: 194PX;
	height: 145PX;
	border-radius: 16PX;
	margin-left: 31PX;
}

.upload-box p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
	margin-top: 40PX;

}

.line {
	background: rgba(37, 44, 53, 0.1);
	height: 1PX;
	margin-top: 31PX;
}

::v-deep .el-radio__label {
	display: none;
}

::v-deep .el-radio__inner {
	width: 19PX;
	height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	border-color: #0A6DF3;
	background: #0A6DF3;
}

.el-radio {
	margin-right: 5PX !important;
}

.radio {
	display: flex;
	align-items: center;
	margin-left: 116PX;
	margin-top: 87PX;
	margin-bottom: 57PX;
}

.radio .sp {
	color: #E6101C;
	margin-right: 8PX;
	font-size: 25PX;
}


.radio p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
}

.jumpto {
	color: #095FFF;
	cursor: pointer;
}

.button {
	display: flex;
	justify-content: center;
	margin-top: 40PX;
}

.save {
	width: 320.2PX;
	height: 67PX;
	background-image: linear-gradient(to right, #6FB2FF, #095FFF);
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.submitt {
	width: 320.2PX;
	height: 67PX;
	background: #A2BAD5;
	margin-left: 67PX;
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.el-select-dropdown__item {
	font-size: 18PX;
}

::v-deep .el-input__icon {
	display: none;
}

::v-deep .el-input--prefix .el-input__inner {
	padding-left: 15PX;
}

.briefintroduction {
	color: #8b8c90;
	margin-left: 140PX;
	font-size: 18PX;
	// margin-right: 10PX;
}
</style>