<template>
    <div class="table">
        <div class="searchfor">
            <el-input placeholder="请输入" v-model="info.matchName" class="level">
                <template slot="prefix">
                    <div style="display: flex;">
                        <span class="searchforlogo">
                            <img src="../../assets/image/eventname2.png" alt="" srcset="">
                        </span>
                        <span class="prefixtitle">赛事名称：</span>
                    </div>
                </template>
            </el-input>
            <el-select placeholder="请选择" class="level" filterable v-model="info.status" clearable>
                <el-option v-for="item in options1" :key="item.itemValue" :label="item.itemText"
                    :value="item.itemValue">
                </el-option>
                <template slot="prefix">
                    <div style="display: flex;">
                        <span class="searchforlogo">
                            <img src="../../assets/image/runproject.png" alt="" srcset="">
                        </span>
                        <span class="prefixtitle">审核状态：</span>
                    </div>
                </template>
            </el-select>
            <div class="but" @click="searchfor">
                <div class="text">搜索</div>
            </div>
            <div class="but2" @click="resetting">
                <div class="text">重置</div>
            </div>
        </div>
        <el-table v-loading="loading" :data="tableData" border style="width: 100%;"
            :header-cell-style="{ background: '#0A6DF3' }" :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column type="index" label="序号" align="center" width="70" sortable>
            </el-table-column>
            <el-table-column prop="matchName" label="赛事名称" align="center">
            </el-table-column>
            <el-table-column prop="enrollName" label="报名人" align="center">
            </el-table-column>
            <el-table-column prop="enrollProject" label="报名项目" align="center"></el-table-column>
            <el-table-column label="提交时间" align="center">
                <template slot-scope="scope">
                    {{ scope.row.submitDate }}
                </template>
            </el-table-column>
            <el-table-column prop="status_dictText" label="审核状态" align="center">
            </el-table-column>
            <el-table-column prop="name" label="操作" align="center">
                <template slot-scope="scope">
                    <div>
                        <el-link type="primary" v-if="scope.row.status == 0 || scope.row.status == 3" :underline="false"
                            style="margin-right: 10PX;" @click="newlyadded(1, scope.row)">编辑</el-link>
                        <el-link type="primary" v-if="scope.row.status == 1 || scope.row.status == 2" :underline="false"
                            style="margin-right: 10PX;" @click="newlyadded(2, scope.row)">报名信息</el-link>
                        <el-link type="primary" v-if="scope.row.status == 3" :underline="false"
                            style="margin-right: 10PX;" @click="newlyadded(3, scope.row)">查看原因</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div class="paging">
            <pagination :total="total" :refreshNum="refreshNum" @currentchange="currentchange" :go="10" :pageSize="10">
            </pagination>
        </div>

        <registration ref="confirm" @refresh="refresh"></registration>
    </div>
</template>

<script>
import registration from '@/components/registrations.vue'
export default {
    name: '',
    components: { registration },
    data() {
        return {
            tableData: [],
            info: {
                matchName: '',
                status: '',
                userId: '',
                pageNo: 1,
                pageSize: 10
            },
            options1: [
                {
                    itemText: '未提交',
                    itemValue: 0
                },
                {
                    itemText: '待审核',
                    itemValue: 1
                },
                {
                    itemText: '审核通过',
                    itemValue: 2
                },
                {
                    itemText: '已退回',
                    itemValue: 3
                },

            ],
            total: 0,
            refreshNum: 1,
            loading: false
        }
    },
    created() {
        this.userEnrollList()
    },
    methods: {
        currentchange(e) {
            this.info.pageNo = e
            this.userEnrollList()
        },
        searchfor() {
            this.info.pageNo = 1
            this.refreshNum = 2
            this.userEnrollList()
        },
        userEnrollList() {
            this.info.userId = this.$store.state.userid
            this.loading = true
            this.$api.userEnrollList(this.info).then(res => {
                this.tableData = res.data.data.result.records
                this.total = res.data.data.result.total
                this.refreshNum = 1
                this.loading = false
            }).catch(error => {
                this.loading = false
            })
        },
        resetting() {
            this.info.matchName = ''
            this.info.status = ''
            this.info.pageNo = 1
            this.info.pageSize = 10
            this.refreshNum = 1
            this.searchfor()
        },
        newlyadded(e, row) {
            if (e == 3) {
                this.$alert(row.rejectReason, '返回原因', {
                    confirmButtonText: '关闭',
                    callback: action => { }
                });
            } else {
                if (e == 1) {
                    if (this.isCurrentTimeGreaterThanTarget(row.matchDateEnd)) {
                        return this.$alert('报名时间已结束，无法编辑', '提示', {
                            confirmButtonText: '关闭',
                            callback: action => { }
                        });
                    }
                }
                let data = {
                    matchId: row.matchId,
                    groupName: row.enrollProject.split(",")[0],
                    projectName: row.enrollProject.split(",")[1],
                    disabled: e == 1 ? false : true,
                    id: row.id
                }
                this.$refs.confirm.open(data)
            }
        },
        refresh() {
            this.userEnrollList()
        },
        isCurrentTimeGreaterThanTarget(targetTime) {
            // 获取当前时间的时间戳（单位：毫秒）
            const currentTimeStamp = new Date().getTime();
            // 使用正则表达式来解析日期时间字符串
            const match = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.exec(targetTime);
            if (!match) {
                // 如果日期时间字符串不匹配预期的格式，返回错误或者做适当处理
                return false;
            }
            // 提取年、月、日、小时、分钟和秒
            const [, year, month, day, hour, minute, second] = match;
            // 构建目标时间的Date对象
            const targetDate = new Date(year, month - 1, day, hour, minute, second);
            // 获取目标时间的时间戳
            console.log(targetDate)
            const targetTimeStamp = targetDate.getTime();
            // 比较当前时间和目标时间
            if (currentTimeStamp > targetTimeStamp) {
                return true;
            } else if (currentTimeStamp < targetTimeStamp) {
                return false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.table {
    margin-top: 40PX;
    margin-bottom: 80PX;
    margin-left: 23PX;
    margin-right: 23PX;
}

.table ::v-deep .el-table thead {
    color: #fff;
    font-size: 16PX;
    font-weight: 500;
}

.table ::v-deep .el-table__header-wrapper {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table {
    border-top-left-radius: 10PX;
    border-top-right-radius: 10PX;
}

.table ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: 1PX solid #333333;
    border-right: 1PX solid #333333;
}

.table ::v-deep .el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
    border-color: #333333;
}

.table ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
    background-color: #333333;
}

.searchfor {
    height: 98PX;
    background: rgba(216, 233, 255, 0.38);
    border-radius: 7PX;
    margin-top: 15PX;
    display: flex;
    justify-content: center;
    margin-bottom: 30PX;
    padding: 0 20PX;
}

.searchfor ::v-deep .el-input__inner {
    height: 43PX;
    background: #FFFFFF;
    border: 1PX solid #003680;
    border-radius: 7PX;
    font-size: 18PX;
    color: #333333;
    padding: 0 0PX 0 155PX;
}

.searchfor ::v-deep input::-webkit-input-placeholder {
    color: #333333;
}

.searchfor ::v-deep input::-moz-input-placeholder {
    color: #333333;
}

.searchfor ::v-deep input::-ms-input-placeholder {
    color: #333333;
}

.searchfor ::v-deep .el-select .el-input .el-select__caret {
    color: #191919;
}

.searchfor .searchforlogo {
    display: block;
    width: 28PX;
    height: 27PX;
    margin-top: 8PX;
    margin-left: 17PX;
}

.searchfor .searchforlogo img {
    width: 100%;
    height: 100%;
}

.searchfor .prefixtitle {
    font-size: 18PX;
    font-weight: bold;
    color: #333333;
    margin-top: 9PX;
    margin-left: 13PX;
}

.searchfor .inp ::v-deep .el-input__inner {
    width: 201PX;
    height: 43PX;
}

.project {
    margin-top: 27PX;
}

.level {
    margin-top: 27PX;
    margin-left: 38PX;
    width: 423PX;
}

.area {
    margin-top: 41PX;
    margin-left: 66PX;
}

.but {
    width: 112.1PX;
    height: 43PX;
    background: #164B92;
    border-radius: 9PX;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 27PX;
    margin-left: 29PX;
    cursor: pointer;
}

.but2 {
    width: 112.1PX;
    height: 43PX;
    background: #348AFE;
    border-radius: 9PX;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 27PX;
    margin-left: 29PX;
    cursor: pointer;
}

.but .text {
    font-size: 19PX;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 7PX;
    letter-spacing: 4PX;
}

.but2 .text {
    font-size: 19PX;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 7PX;
    letter-spacing: 4PX;
}

.el-select-dropdown__item {
    font-size: 18PX;
}
</style>